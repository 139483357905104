import React, { FC } from 'react'
import desktopBackgroundImage from '../../images/services/banner-liquidites-desktop.jpg'
import mobileBackgroundImage from '../../images/services/banner-liquidites-mobile.jpg'
import { Colors } from '../../colors'
import ServicePageLayout from '../../components/layouts/service-page-layout'
import { AvailableServices } from '../../available-services'
import { ServiceDetail } from '../../components/service-detail/service-detail'

export const GestionDeLiquidites: FC = () => (
    <ServicePageLayout
        primaryColor={Colors.yellow}
        title="Gestion de liquidités (cashflow)"
        desktopBanner={desktopBackgroundImage}
        mobileBanner={mobileBackgroundImage}
        bannerText="Gestion de liquidités. Faites croître votre cashflow."
        serviceBrief={`Il existe une gamme impressionnante de solutions bancaires.Chacune d’entre elles vous offre des caractéristiques et des avantages pour vous aider à naviguer à travers vos finances personnelles et à les faire croître. Qu’il s’agisse d’un prêt hypothécaire, d'une marge de crédit ou d'un compte bancaire, il y a toujours une solution unique adaptée à vos besoins.`}
        serviceToHide={AvailableServices.liquidites}
    >
        <>
            <ServiceDetail
                color={Colors.turquoise}
                title="Comptes de banque"
                details="Pour gérer vos opérations bancaires courantes."
            />
            <ServiceDetail
                color={Colors.skyBlue}
                title="Prêt REER, CELI et REEE"
                details="Pour obtenir le financement afin de réaliser vos objectifs plus rapidement."
            />
            <ServiceDetail
                color={Colors.yellow}
                title="Compte à intérêt élevé"
                details="Pour obtenir un taux d’intérêt intéressant sur votre épargne à court terme."
            />
            <ServiceDetail
                color={Colors.purple}
                title="Prêt investissement"
                details={`Pour augmenter le potentiel de croissance de vos placement.`}
            />
            <ServiceDetail
                color={Colors.skyBlue}
                title="Marge de crédit"
                details="Pour répondre à un besoin de financement rapide."
            />
            <ServiceDetail
                color={Colors.turquoise}
                title="Hypothèque"
                details={`Pour simplifier l’achat d’une propriété ou pour renouveler votre hypothèque.`}
            />
        </>
    </ServicePageLayout>
)

export default GestionDeLiquidites
